import React from "react";

interface Props {
  width?: string;
  height?: string;
  color?: string;
}

const Arrow = ({ width, height, color }: Props) => {
  return (
    <svg
      width={width || "12"}
      height={height || width || "13"}
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.343186 10.7426C-0.0473381 11.1332 -0.0473381 11.7663 0.343186 12.1569C0.733711 12.5474 1.36688 12.5474 1.7574 12.1569L0.343186 10.7426ZM11.9498 1.55026C11.9498 0.997973 11.5021 0.550258 10.9498 0.550258L1.94979 0.550258C1.3975 0.550258 0.949788 0.997973 0.949788 1.55026C0.949788 2.10254 1.3975 2.55026 1.94979 2.55026L9.94979 2.55026L9.94979 10.5503C9.94979 11.1025 10.3975 11.5503 10.9498 11.5503C11.5021 11.5503 11.9498 11.1025 11.9498 10.5503L11.9498 1.55026ZM1.7574 12.1569L11.6569 2.25736L10.2427 0.843151L0.343186 10.7426L1.7574 12.1569Z"
        fill={color || "#B56959"}
      />
    </svg>
  );
};

export default Arrow;
