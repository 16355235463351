import styled from "@emotion/styled";
import { mq } from "./media-query";

export const Title = styled.h2`
  font-style: normal;
  font-weight: 700;
  font-size: ${({ theme }) => theme.fontSizes.h2};
  line-height: 72px;
  margin: 0px;

  ${mq["md"]} {
    font-size: 36px;
    line-height: 54px;
  }
  ${(props: any) => ({ ...props })};
`;

export const Heading = styled.h3<{
  gradient?: boolean;
}>`
  font-style: normal;
  font-weight: 700;
  font-size: ${({ theme }) => theme.fontSizes.h3};
  display: inline;
  color: ${({ color }) => color || "black"};
  ${(props) =>
    props.gradient &&
    ` background: -webkit-linear-gradient(134.58deg, #CBB898 0%, #AC9470 78.65%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;`}

  line-height: 54px;
  letter-spacing: 0.25em;
  margin: 0px;

  ${mq["lg"]} {
    font-size: 30px;
    letter-spacing: 0.2em;
  }

  ${mq["md"]} {
    font-size: 24px;
    letter-spacing: normal;
  }

  ${(props: any) => ({ ...props })};
`;

export const SubHeading = styled.h4<{
  position?: string;
  bottom?: string;
  left?: string;
  gradient?: boolean;
}>`
  font-style: normal;
  font-weight: 700;
  font-size: ${({ theme }) => theme.fontSizes.h4};
  line-height: 39px;
  margin: 0px;
  display: inline;
  ${(props) =>
    props.gradient &&
    ` background: -webkit-linear-gradient(134.58deg, #CBB898 0%, #AC9470 78.65%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;`}
  ${mq["lg"]} {
    font-size: 20px;
  }

  ${mq["md"]} {
    font-size: 18px;
  }

  ${(props: any) => ({ ...props })};
`;

export const Paragraph = styled.p<{
  margin?: string;
  cursor?: string;
  padding?: string;
  gradient?: boolean;
}>`
  font-style: normal;
  font-weight: 400;
  font-size: ${({ theme }) => theme.fontSizes.p};
  line-height: 24px;
  ${mq["header"]} {
    font-size: 14px;
  }
  ${(props) =>
    props.gradient &&
    ` background: -webkit-linear-gradient(134.58deg, #CBB898 0%, #AC9470 78.65%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;`}
  ${(props: any) => ({ ...props })};
`;
