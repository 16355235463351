import React, { useEffect, useRef, useState } from "react";

import ArrowButton from "@/common/arrow-button";
import { navigators } from "constants/header";
import { Link } from "gatsby";
import LanguagePicker from "./components/language-picker";
import { css } from "@emotion/react";
import { DesktopNav, NavItem } from "./DesktopNavPrimitive";
import { MobNav } from "./MobNav";
import useIntersectionObserver from "@s/useIntersectionObserver";
import styled from "@emotion/styled";
import { ReactComponent as Logo } from "../../../../assets/logo.svg";

const Navbar = styled.header<{ scrolled: boolean }>`
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  border-bottom: 1px solid transparent;
  z-index: 100;
  transition: border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
  box-sizing: border-box;

  > div:last-of-type {
    display: none;
  }

  ${({ scrolled, theme }) =>
    scrolled &&
    css`
      border-color: ${theme.colors.tomatoRed};

      @media (max-width: 1279px) {
        background-color: ${theme.colors.tomatoRed};
      }
    `};

  @media (min-width: 1280px) {
    background-color: ${({ theme }) => theme.colors.white};
    padding: 8px 32px;

    > div:last-of-type {
      display: block;
    }
  }

  @media (min-width: 1600px) {
    padding: 8px 64px;
  }

  @media (min-width: 1921px) {
    padding: 8px calc((100vw - 1920px) / 2);
  }
`;

const menuStyles = css`
  display: none;

  > li:last-of-type {
    margin-right: -17px;
  }

  @media (min-width: 1280px) {
    display: flex;
  }
`;

const contentStyles = css`
  top: calc((1rem * 1.5) + 12px);
`;

const Observe = styled.span`
  position: absolute;
  width: 1px;
  height: 1px;
  top: 10px;
  left: 0;
  z-index: -111;
`;

const HomeLink = styled(Link)`
  transition: opacity 0.3s ease-in-out;

  :hover {
    opacity: 0.7;
  }

  :focus-visible {
    opacity: 0.7;
  }

  @media (max-width: 1279px) {
    > svg {
      width: 140px;
      height: 73.47px;

      > path {
        fill: ${({ theme }) => theme.colors.white};
      }
    }
  }
`;

export const HomeLogo = () => (
  <HomeLink to="/">
    <Logo />
  </HomeLink>
);

const Header = () => {
  const [scrolled, setScrolled] = useState(false);

  const observeRef = useRef<HTMLElement | null>(null);

  const visible = useIntersectionObserver(observeRef, {});

  useEffect(() => {
    const timeout = setTimeout(() => {
      setScrolled(!visible);
    }, 100);
    return () => {
      clearTimeout(timeout);
    };
  }, [visible]);

  return (
    <>
      <Navbar scrolled={scrolled}>
        <MobNav />
        <HomeLogo />

        <DesktopNav
          menuStyles={menuStyles}
          contentStyles={contentStyles}
          links={navigators}
        >
          <NavItem>
            <LanguagePicker margin="0px 16px 0px 0px" whiteArrow />
          </NavItem>

          <NavItem
            css={css`
              @media (max-width: 1420px) {
                display: none;
              }
            `}
          >
            <Link to="/contact">
              <ArrowButton title="Book An Appointment" />
            </Link>
          </NavItem>
        </DesktopNav>
      </Navbar>
      <Observe ref={observeRef} />
    </>
  );
};

export default Header;
