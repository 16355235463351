import React from 'react'

interface Props {
    width?: string;
    height?: string;
    color?: string;
}

const FacebookIcon = ({ width, height, color }: Props) => {
    return (
        <svg width={width || "24"} height={height || "25"} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M0 12.754C0 18.72 4.333 23.681 10 24.687V16.02H7V12.687H10V10.02C10 7.02001 11.933 5.35401 14.667 5.35401C15.533 5.35401 16.467 5.48701 17.333 5.62001V8.68701H15.8C14.333 8.68701 14 9.42001 14 10.354V12.687H17.2L16.667 16.02H14V24.687C19.667 23.681 24 18.721 24 12.754C24 6.11701 18.6 0.687012 12 0.687012C5.4 0.687012 0 6.11701 0 12.754Z" fill={color || "#F2F2F2"} />
        </svg>

    )
}

export default FacebookIcon