type Seo = {
  [index: string]: {
    t: string;
    d?: string;
  };
};

export const seoData: Seo = {
  contact: {
    t: "Contact Pediatric Dentistry and Orthodontics Services in Mt.Juliet TN",
    d: "Ask any questions about your oral health. We will provide a detailed consultation to ensure your treatments are effective and affordable.",
  },
  about: {
    t: 'About Pediatric Dentistry and Orthodontics Services in Mt.Juliet TN"- Pearl Pediatric Dentistry and Orthodontics',
    d: "Our Team of Pediatric Dentistry a	nd Orthodontics Professionals are here to serve you and provide you with a Healthy, Happy Smile that will brighten your day.",
  },
  "pediatric-dentistry": {
    t: "Pediatric Dentistry Treatment Services in Mt. Juliet, TN - Pearl Pediatric Dentistry and Orthodontics",
    d: "At Pearl Pediatric Dentistry, We Are Pleased to Offer Pediatric Services. Our Dental Team is Well Qualified and Experienced with All Ages. Visit Us Today!",
  },
  "metal-braces": {
    t: " Traditional Metal Braces in Mt. Juliet TN - Pearl Pediatric Dentistry and Orthodontics",
    d: "At Pearl Pediatric Dentistry, we'll help you transform your smile with traditional metal braces personalized to fit your needs and goals. Book an Appointment",
  },
  "clear-aligners": {
    t: "Invisalign Clear Aligners Treatment Mt. Juliet TN - Pearl Pediatric Dentistry and Orthodontics",
    d: "Invisalign Clear Aligners Treatment is the alternative to metal braces for kids, teens, and adults. Find an Invisalign-trained orthodontist near you today!.",
  },
  sedation: {
    t: "Sedation Services in Mt. Juliet TN - Pearl Pediatric Dentistry and Orthodontics",
    d: "In Office Pearl Pediatric Dentistry service providing monitored sedation for dental and oral surgical procedures throughout the Tennessee area.",
  },
  "dental-cleaning": {
    t: "Pediatric Dental Cleanings In TN - Pearl Pediatric Dentistry and Orthodontics",
    d: "Pearl Pediatric Dentistry is your Memphis, TN pediatric dentist, providing quality dental care for children and teens. Call us today!",
  },
  "clear-braces": {
    t: "Clear Braces Service in Mt. Juliet TN - Pearl Pediatric Dentistry and Orthodontics",
    d: "Clear braces are made with tooth-colored ceramic material to blend in with your smile and deliver a less noticeable—but effective—orthodontic treatment.",
  },
  "first-dental-visit": {
    t: "First Dental Visit - Pearl Pediatric Dentistry and Orthodontics",
    d: "Your child's first dental visit is to help your child feel comfortable with the dentist. The first dental visit is recommended 6 months after the first tooth comes in.",
  },
  "orthodontic-treatment-for-adults": {
    t: "Orthodontic Treatment for Adults Mt. Juliet TN - Pearl Pediatric Dentistry and Orthodontics",
    d: "Orthodontic treatment  for adults creates a better bite, making teeth fit better and decreasing the risk of future dental problems regardless of age.",
  },
  "preventive-dental-treatments": {
    t: "Preventive Dentistry Treatments in Mt. Juliet TN - Pearl Pediatric Dentistry and Orthodontics",
    d: "Preventive dentistry treatments care includes actions taken by a patient to prevent oral diseases and maintain a healthy smile for a lifetime.",
  },
  "restorative-dentistry-specialist": {
    t: "Restorative Dentistry Services Mt. Juliet TN - Pearl Pediatric Dentistry and Orthodontics",
    d: "Restorative dentistry involves the study, diagnosis and integrated management of diseases of the oral cavity, the teeth and supporting structures.",
  },
  "general-anesthesia": {
    t: "General Anesthesia Service in Mt. Juliet TN - Pearl Pediatric Dentistry and Orthodontics",
    d: "General anesthesia is a combination of medications that put you in a sleep-like state before a surgery or other medical procedure.",
  },
  "dental-emergency-treatment": {
    t: "Dental Emergency Treatment in Mt. Juliet TN - Pearl Pediatric Dentistry and Orthodontics",
    d: "Emergency dental care treatment in Mt Juliet at Pearl Pediatric Dentistry Dental Group. If you need immediate assistance, call for a same-day appointment.",
  },
  "special-dental-needs": {
    t: "Special Needs Dental Clinic in Mt. Juliet TN - Pearl Pediatric Dentistry and Orthodontics",
    d: "The Pearl Pediatric Dentistry with Special Needs provides a full range of services, including dental cleanings and X-rays, restorative dentistry, root canals",
  },
  "adolescent-treatment": {
    t: "Adolescent Dental Treatment Mt. Juliet TN - Pearl Pediatric Dentistry and Orthodontics",
    d: "Your smile is one of the first things people notice about you. Learn more about adolescent dental treatment for teens such as how to prevent cavities and gum disease.",
  },
  "pediatric-dental-fillings": {
    t: "Pediatric Dental Fillings Services In Mt. Juliet, TN - Pearl Pediatric Dentistry and Orthodontics",
    d: "Contact Pearl Pediatric Dentistry to schedule an appointment for preventive care or to get dental filling services for your child. Book an Appointment.",
  },
  "interceptive-orthodontic-treatment": {
    t: " Interceptive Orthodontic Treatment in Mt. Juliet TN - Pearl Pediatric Dentistry and Orthodontics",
    d: "Interceptive orthodontics is an approach that uses phased treatments to manipulate growth and correct developmental occlusion problems. Call Us Today!.",
  },
  "office-tour": {
    t: "Office Tour - Pearl Pediatric Dentistry and Orthodontics",
    d: "Let’s take a virtual tour of our office.",
  },
};
