import { css } from "@emotion/react";
import { theme } from "@/GlobalStyles";

const dynamicStyles = css`
  &[aria-current="page"] {
    ::after {
      background-color: ${theme.colors.lightGray};
    }
  }

  &.current-nav-category {
    ::after {
      background-color: ${theme.colors.lightGray};
    }
  }

  &[data-mobile-expanded],
  &[aria-expanded="true"] {
    > svg {
      transform: rotate(180deg);
    }
  }

  :hover {
    ::after {
      background-color: #f2f2f2;
    }
  }

  :focus-visible {
    ::after {
      background-color: #f2f2f2;
    }
  }

  @media (min-width: 1280px) {
    &[aria-current="page"] {
      ::after {
        background-color: ${theme.colors.darkGolden};
      }
    }

    &.current-nav-category {
      ::after {
        background-color: ${theme.colors.darkGolden};
      }
    }

    :hover {
      ::after {
        background-color: ${theme.colors.tomatoRed};
      }
    }

    :focus-visible {
      ::after {
        background-color: ${theme.colors.tomatoRed};
      }
    }
  }
`;

export const navLinkStyles = css`
  ${dynamicStyles};
  display: block;
  text-transform: uppercase;
  color: #f2f2f2;
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
  margin-bottom: 32px;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 150%;
  outline: none;
  position: relative;

  ::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: -8px;
    height: 2px;
    transition: background-color 0.3s ease-in-out;
  }

  @media (min-width: 1280px) {
    font-size: 1rem;
    margin-bottom: 0 !important;
    margin-right: 32px;
    text-transform: capitalize;
    color: ${theme.colors.tomatoRed};
  }
`;

export const subNavLinkStyles = css`
  ${navLinkStyles};
  font-weight: 400;
  margin-bottom: 12px;
  padding-bottom: 4px;
  max-width: max-content;

  ::after {
    height: 1px;
    bottom: -2px;
  }

  @media (min-width: 1280px) {
    color: ${theme.colors.black};
    padding: 8px 16px;
    background-color: ${theme.colors.white};
    border-width: 2px;
    font-weight: 700;
    max-width: 100%;

    ::after {
      height: 2px;
      bottom: 0;
    }

    :hover {
      background-color: ${theme.colors.offWhite};

      ::after {
        background-color: ${theme.colors.darkYellow};
      }
    }

    :focus-visible {
      background-color: ${theme.colors.offWhite};

      ::after {
        background-color: ${theme.colors.darkYellow};
      }
    }
  }
`;

export const navBtnStyles = css`
  border: none;
  background: transparent;
  outline: none;
  padding: 0;
  cursor: pointer;
  ${navLinkStyles};
  display: flex;
  align-items: center;

  > svg {
    margin-left: 10px;
    transition: transform 0.3s ease-in-out;
    flex-shrink: 0;

    path {
      transition: fill 0.3s ease-in-out;
      stroke: ${theme.colors.white};
    }
  }

  @media (min-width: 1280px) {
    > svg > path {
      stroke: ${theme.colors.tomatoRed};
    }
  }
`;
