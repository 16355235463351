import Image from "@/common/image";
import { theme } from "@/GlobalStyles";
import { css } from "@emotion/react";
import { Link } from "gatsby";
import { Heading, Paragraph } from "styles/typography";
import FacebookIcon from "svgs/facebook-icon";
import InstagramIcon from "svgs/instagram-icon";
import Mail from "svgs/mail";
import ModernLogo from "svgs/modern-logo";
import Phone from "svgs/phone";
import {
  ContactContainer,
  ContentContainer,
  DivWithIcon,
  MainContainer,
  NavigationContainer,
  NavigationOptions,
  PoweredBy,
  PoweredText,
  SocialContainer,
  Socials,
} from "./footer";

const Footer = () => {
  return (
    <MainContainer>
      <Image
        filename="logo-white.png"
        alt="Logo"
        width="182.65px"
        height="96px"
      />
      <ContentContainer>
        <NavigationContainer marginRight="110px">
          <Heading color={theme.colors.white} letterSpacing="0.1px">
            NAVIGATE
          </Heading>
          <NavigationOptions>
            <Link to="/blog/">
              <Paragraph
                color={theme.colors.white}
                fontWeight="700"
                cursor="pointer"
              >
                Blog
              </Paragraph>
            </Link>
            <Link to="/contact">
              <Paragraph
                color={theme.colors.white}
                fontWeight="700"
                cursor="pointer"
              >
                Contact Us
              </Paragraph>
            </Link>
            <Link to="/about">
              <Paragraph
                color={theme.colors.white}
                fontWeight="700"
                cursor="pointer"
              >
                Meet the Doctors
              </Paragraph>
            </Link>
          </NavigationOptions>
        </NavigationContainer>

        <ContactContainer>
          <Heading
            css={css`
              margin-left: 20px;
              letter-spacing: 0.1px;
            `}
            color={theme.colors.white}
          >
            QUESTIONS?
          </Heading>
          <NavigationOptions>
            <DivWithIcon>
              <Phone />
              <a href="tel:+6153018660">
                <Paragraph
                  color={theme.colors.white}
                  css={css`
                    font-weight: 700;
                    cursor: pointer;
                    margin-left: 15px;

                    @media (min-width: 1280px) {
                      font-size: 20px;
                    }
                  `}
                >
                  {" "}
                  (615) 301-8660
                </Paragraph>
              </a>
            </DivWithIcon>
            <DivWithIcon>
              <Mail />
              <a href="mailto:hello@pearlpdo.com">
                <Paragraph
                  color={theme.colors.white}
                  css={css`
                    font-weight: 700;
                    cursor: pointer;
                    margin-left: 15px;

                    @media (min-width: 1280px) {
                      font-size: 20px;
                    }
                  `}
                >
                  {" "}
                  hello@pearlpdo.com
                </Paragraph>
              </a>
            </DivWithIcon>
          </NavigationOptions>
        </ContactContainer>

        <SocialContainer>
          <Heading color={theme.colors.white} letterSpacing="0.1px">
            FOLLOW US!
          </Heading>
          <Socials>
            <div style={{ marginRight: 32 }}>
              <a
                href="https://www.facebook.com/profile.php?id=100086023868364"
                target="_blank"
                rel="noreferrer"
                aria-label="checkout pearl pediatric dentistry facebook page"
              >
                <FacebookIcon />
              </a>
            </div>
            <a
              href="https://www.instagram.com/pearl_pdo/?hl=en"
              target="_blank"
              rel="noreferrer"
              aria-label="checkout pearl pediatric dentistry instagram page"
            >
              <InstagramIcon />
            </a>
          </Socials>
        </SocialContainer>
      </ContentContainer>

      <a href="https://moderndocmedia.com" target="_blank" rel="noreferrer">
        <PoweredBy>
          <ModernLogo />
          <PoweredText>POWERED BY MODERN DOC MEDIA.</PoweredText>
        </PoweredBy>
      </a>
    </MainContainer>
  );
};

export default Footer;
