// eslint-disable-next-line
import { useLocation } from '@reach/router';
import { graphql, useStaticQuery } from "gatsby";
import { IGatsbyImageData } from "gatsby-plugin-image";
import { Helmet } from "react-helmet";
import { seoData } from "./seoData";

type SeoProps = {
  title?: string;
  description?: string;
  meta?: { name: string; content: unknown; property?: undefined }[];
  keywords?: string;
  image?: IGatsbyImageData;
};

export const Seo = ({
  description,
  meta = [],
  title,
  keywords,
  image,
}: SeoProps) => {
  const { site, og } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            keywords
            siteUrl
          }
        }
        og: file(relativePath: { eq: "default-og.png" }) {
          childImageSharp {
            gatsbyImageData(quality: 100, width: 630, height: 630)
          }
        }
      }
    `
  );

  const location = useLocation();
  const url = location.pathname.slice(1, -1).split("/");

  const pageTitle =
    url.length === 1
      ? url[0]
      : url.length === 2
      ? url[1]
      : url.length === 3
      ? url[2]
      : "";

  let siteUrl = site.siteMetadata.siteUrl;
  if (siteUrl.endsWith("/")) siteUrl = siteUrl.slice(0, -1);

  const theTitle = title
    ? title.replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase())
    : seoData[pageTitle]
    ? seoData[pageTitle].t
    : site.siteMetadata.title;

  const metaDescription = description
    ? description.charAt(0).toUpperCase() + description.slice(1).toLowerCase()
    : seoData[pageTitle]
    ? seoData[pageTitle].d
    : site.siteMetadata.description;

  const theKeywords = keywords || site.siteMetadata.keywords;

  const imageData = image || og.childImageSharp.gatsbyImageData;
  const imageUrl = siteUrl + imageData.images.fallback.src;

  return (
    <Helmet
      htmlAttributes={{ lang: "en" }}
      title={theTitle}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `keywords`,
          content: theKeywords,
        },
        {
          property: `og:title`,
          content: theTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: imageUrl,
        },
        {
          property: "og:image:width",
          content: imageData.width,
        },
        {
          property: "og:image:height",
          content: imageData.height,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `google-site-verification`,
          content: `n33Ggc94Zd3KIAG9i58Uw_KzLEaWh-KtiboYH1QKCrk`,
        },
      ].concat(meta)}
    />
  );
};
