import React from 'react'

interface Props {
    width?: string;
    height?: string;
    color?: string;
}

const Phone = ({ width, height, color }: Props) => {
    return (
        <svg width={width || "13"} height={height || "17"} viewBox="0 0 13 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.23987 0.774108C3.67745 0.642174 4.14702 0.664093 4.57041 0.836214C4.9938 1.00834 5.34547 1.32028 5.56687 1.72011L5.64187 1.86911L6.30287 3.34011C6.50378 3.78659 6.56899 4.28229 6.49041 4.76554C6.41182 5.2488 6.1929 5.69829 5.86087 6.05811L5.72887 6.19011L4.68487 7.16311C4.49687 7.34111 4.63787 8.03011 5.31887 9.20811C5.92987 10.2681 6.42887 10.7631 6.67287 10.7901H6.71587L6.76887 10.7801L8.81887 10.1531C9.09438 10.0686 9.3884 10.0653 9.66576 10.1435C9.94312 10.2217 10.1921 10.3781 10.3829 10.5941L10.4749 10.7091L11.8309 12.5891C12.097 12.9575 12.2297 13.4055 12.2071 13.8594C12.1846 14.3132 12.0081 14.7459 11.7069 15.0861L11.5849 15.2121L11.0419 15.7261C10.5552 16.187 9.94634 16.4985 9.28777 16.6235C8.6292 16.7485 7.9486 16.6817 7.32687 16.4311C5.39187 15.6511 3.63387 13.8691 2.03687 11.1031C0.43687 8.33011 -0.22813 5.91311 0.0688697 3.84311C0.158831 3.21726 0.416647 2.62747 0.814926 2.1364C1.21321 1.64533 1.73707 1.27132 2.33087 1.05411L2.52387 0.990108L3.23987 0.774108V0.774108Z" fill={color || "#F2F2F2"} />
        </svg>

    )
}

export default Phone