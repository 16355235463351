import React from 'react'

interface Props {
    width?: string;
    height?: string;
    color?: string;
}

const ModernLogo = ({ width, height, color }: Props) => {
    return (
        <svg width={width || "26"} height={height || "35"} viewBox="0 0 26 35" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M1.11218 10.3169L8.55472 11.2146L16.0262 1.68701V18.9178L1.11218 27.5477V10.3169Z" stroke={color || "white"} stroke-miterlimit="10" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M23.353 21.0028C23.3819 20.9449 23.3819 20.9159 23.4109 20.858C23.5267 20.5974 23.6426 20.3367 23.7294 20.0761C23.7584 20.0471 23.7584 19.9892 23.7584 19.9603C23.8742 19.6996 23.9611 19.41 24.048 19.1494C24.1349 18.8888 24.2217 18.5992 24.3086 18.3385C24.3086 18.3096 24.3376 18.2517 24.3376 18.2227C24.3955 17.9621 24.4824 17.7014 24.5403 17.4408C24.5403 17.3829 24.5692 17.3539 24.5692 17.296C24.6272 17.0354 24.6851 16.7747 24.714 16.5141C24.714 16.4851 24.714 16.4272 24.743 16.3983C24.772 16.1376 24.8009 15.877 24.8299 15.6164V15.5874C24.8588 15.2399 24.8878 14.9213 24.8878 14.5738C24.8878 11.9965 23.9611 10.172 22.4842 9.30324C21.0073 8.43446 18.9511 8.5503 16.7213 9.85346C15.3602 10.6354 13.9991 11.4173 12.638 12.1992C11.2769 12.9811 9.91584 13.763 8.55475 14.5449C8.55475 17.7014 8.55475 20.829 8.55475 23.9856C8.55475 27.1422 8.55475 30.5594 8.55475 33.687C11.2769 32.1232 13.9412 30.2987 16.7213 28.677C17.0109 28.5033 17.3005 28.3295 17.5611 28.1268C17.5611 28.1268 17.5611 28.1268 17.5901 28.1268C17.7928 27.982 18.0244 27.8082 18.2272 27.6634C18.2561 27.6345 18.2851 27.6345 18.314 27.6055C18.5168 27.4318 18.7195 27.258 18.9222 27.0843C18.9511 27.0553 19.0091 27.0263 19.038 26.9974C19.2407 26.8236 19.4145 26.6209 19.6172 26.4471C19.6462 26.4182 19.6751 26.3892 19.7041 26.3603C19.9068 26.1576 20.0806 25.9548 20.2833 25.7521C20.2833 25.7521 20.2833 25.7521 20.3122 25.7232C20.486 25.5204 20.6887 25.2888 20.8625 25.0861C20.8914 25.0571 20.9204 25.0281 20.9493 24.9992C21.1231 24.7965 21.2968 24.5648 21.4416 24.3331C21.4706 24.3042 21.4996 24.2462 21.5285 24.2173C21.7023 23.9856 21.8471 23.7539 21.9919 23.5223C22.0208 23.4933 22.0498 23.4354 22.0498 23.4064C22.1946 23.1458 22.3683 22.9141 22.5131 22.6535C22.6579 22.3928 22.8027 22.1322 22.9475 21.8716C22.9765 21.8426 22.9765 21.7847 23.0054 21.7557C23.1213 21.5241 23.2371 21.2634 23.353 21.0028Z" stroke={color || "white"} stroke-miterlimit="10" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M8.58377 14.6029C9.9159 13.8499 11.248 13.068 12.5512 12.3151C13.7675 11.6201 14.81 11.0119 16.0263 10.3169V18.9468L8.58377 23.2327V14.6029Z" stroke={color || "white"} stroke-miterlimit="10" />
        </svg>
    )
}

export default ModernLogo