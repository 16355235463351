import React from 'react'

interface Props {
    width?: string;
    height?: string;
    color?: string;
}

const Mail = ({ width, height, color }: Props) => {
    return (
        <svg width={width || "20"} height={height || "17"} viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17 0.687012H3C2.20435 0.687012 1.44129 1.00308 0.87868 1.56569C0.316071 2.1283 0 2.89136 0 3.68701V13.687C0 14.4827 0.316071 15.2457 0.87868 15.8083C1.44129 16.3709 2.20435 16.687 3 16.687H17C17.7956 16.687 18.5587 16.3709 19.1213 15.8083C19.6839 15.2457 20 14.4827 20 13.687V3.68701C20 2.89136 19.6839 2.1283 19.1213 1.56569C18.5587 1.00308 17.7956 0.687012 17 0.687012ZM17 2.68701L10.5 7.15701C10.348 7.24478 10.1755 7.29099 10 7.29099C9.82446 7.29099 9.65202 7.24478 9.5 7.15701L3 2.68701H17Z" fill={color || "#F2F2F2"} />
        </svg>

    )
}

export default Mail