import styled from "@emotion/styled";
import * as AccordionPrimitive from "@radix-ui/react-accordion";
import { forwardRef, ReactNode, Ref } from "react";

export const Accordion = AccordionPrimitive.Root;

export const AccordionItem = AccordionPrimitive.Item;

export const AccordionContent = styled(AccordionPrimitive.Content)`
  overflow: hidden;

  &[data-state="open"] {
    animation: slideDown 300ms ease-out;
  }

  &[data-state="closed"] {
    animation: slideUp 300ms ease-out;
  }

  @keyframes slideDown {
    from {
      height: 0;
    }
    to {
      height: var(--radix-accordion-content-height);
    }
  }

  @keyframes slideUp {
    from {
      height: var(--radix-accordion-content-height);
    }
    to {
      height: 0;
    }
  }
`;

type BtnProps = {
  children: ReactNode;
  className?: string;
} & AccordionPrimitive.AccordionTriggerProps;

// eslint-disable-next-line react/display-name
export const AccordionBtn = forwardRef(
  (
    { children, className, ...props }: BtnProps,
    forwardedRef: Ref<HTMLButtonElement>
  ) => (
    <AccordionPrimitive.Header style={{ margin: 0 }}>
      <AccordionPrimitive.Trigger
        className={className}
        ref={forwardedRef}
        {...props}
      >
        {children}
      </AccordionPrimitive.Trigger>
    </AccordionPrimitive.Header>
  )
);
