import Image from "@/common/image";
import styled from "@emotion/styled";
import React, { useState } from "react";

const DefaultLanguage = styled.div`
  display: flex;
  align-items: center;
`;
const Languages = styled.div`
  margin-top: 20px;
`;
const LanguageChanger = styled.div<{
  margin?: string;
  background?: string;
}>`
  display: flex;
  padding: 8px;
  align-items: center;
  flex-direction: column;
  min-width: 60px;
  background: ${({ theme, background }) =>
    background || theme.colors.tomatoRed};
  border-radius: 16px;
  font-weight: 700;
  font-size: 14px;
  color: ${({ theme, color }) => color || theme.colors.offWhite};
  cursor: pointer;
  justify-self: flex-start;
  ${(props) => props.margin && `margin: ${props.margin}`};
`;

export default function LanguagePicker({
  background,
  color,
  margin,
  whiteArrow,
}: any) {
  const initialLang =
    typeof document !== "undefined" &&
    document.cookie.includes("googtrans=/en/es")
      ? "ESP"
      : "ENG";

  const [currentLang, setCurrentLang] = useState(initialLang);
  const [langPickerOpen, setLangPickerOpen] = useState(false);

  const languages = ["ENG", "ESP"];

  const handleLanguageChange = (lang: string) => {
    setCurrentLang(lang);
    setLangPickerOpen(false);
    document.cookie = `googtrans=;domain=.${
      window.location.hostname
    };expires=${new Date(0).toUTCString()}`;
    document.cookie = `googtrans=/en/${lang.toLowerCase().slice(0, 2)};`;
    window.location.reload();
  };

  return (
    <LanguageChanger background={background} color={color} margin={margin}>
      <DefaultLanguage onClick={() => setLangPickerOpen(!langPickerOpen)}>
        <span className="notranslate">{currentLang}</span>
        <div>
          {!langPickerOpen &&
            (whiteArrow ? (
              <Image
                filename="white-down-arrow.png"
                alt="lang"
                width="10px"
                height="5px"
                marginLeft="10px"
              />
            ) : (
              <Image
                filename="lang-arrow.png"
                alt="lang"
                width="10px"
                height="5px"
                marginLeft="10px"
              />
            ))}
        </div>
      </DefaultLanguage>

      {/* //Other Languages */}
      {langPickerOpen &&
        languages.map(
          (item: any, index: any) =>
            item != currentLang && (
              <Languages key={index} onClick={() => handleLanguageChange(item)}>
                <span className="notranslate">{item}</span>
              </Languages>
            )
        )}
    </LanguageChanger>
  );
}
