import { ReactComponent as ChevronIcon } from "../../../../assets/icons/nav-chevron.svg";
import { SerializedStyles } from "@emotion/react";
import styled from "@emotion/styled";
import * as NavigationMenu from "@radix-ui/react-navigation-menu";
import useLocationData from "@s/useLocationData";
import { NavLinksTypes } from "../../../constants/header";
import { navBtnStyles, navLinkStyles, subNavLinkStyles } from "./NavLinkStyles";
import { ReactNode } from "react";
import { Link } from "gatsby";

const MenuList = styled(NavigationMenu.List)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  list-style-type: none;
  padding-inline-start: 0;
  margin: 0;

  > li {
    position: relative; // for submenu align with item
  }
`;

const Content = styled(NavigationMenu.Content)`
  position: absolute;
  top: 0;
  left: 0;
  width: max-content;
  animation-duration: 300ms;
  animation-timing-function: ease;
  z-index: 100;

  > ul {
    list-style-type: none;
    padding-inline-start: 0;
    margin: 0;
  }

  &[data-state="open"],
  &[data-motion="from-start"] {
    animation-name: enterFromBottom;
  }

  &[data-motion="from-end"] {
    animation-name: enterFromBottom;
  }

  &[data-state="closed"],
  &[data-motion="to-start"] {
    animation-name: exitToBottom;
  }
  &[data-motion="to-end"] {
    animation-name: exitToBottom;
  }

  @keyframes enterFromBottom {
    from {
      opacity: 0;
      transform: translateY(50px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes exitToBottom {
    from {
      opacity: 1;
      transform: translateY(0);
    }
    to {
      opacity: 0;
      transform: translateY(50px);
    }
  }
`;

type MenuProps = {
  children: ReactNode;
  menuStyles?: SerializedStyles;
  contentStyles: SerializedStyles;
  links: NavLinksTypes;
};

export const DesktopNav = ({
  children,
  menuStyles,
  contentStyles,
  links,
}: MenuProps) => {
  const { title, category } = useLocationData();

  return (
    <NavigationMenu.Root asChild>
      <MenuList css={menuStyles}>
        {links.map((data, i) =>
          data.links ? (
            <NavigationMenu.Item key={i}>
              <NavigationMenu.Trigger
                css={navBtnStyles}
                className={category === data.text ? "current-nav-category" : ""}
              >
                {data.text}
                <ChevronIcon />
              </NavigationMenu.Trigger>
              <Content css={contentStyles}>
                <ul>
                  {data.links?.map((subData, i) => (
                    <li key={i}>
                      <NavigationMenu.Link
                        asChild
                        active={subData.text === title}
                      >
                        <Link
                          to={subData.link as string}
                          css={subNavLinkStyles}
                        >
                          {subData.text}
                        </Link>
                      </NavigationMenu.Link>
                    </li>
                  ))}
                </ul>
              </Content>
            </NavigationMenu.Item>
          ) : (
            <NavigationMenu.Item key={i}>
              <NavigationMenu.Link asChild active={data.text === title}>
                <Link to={data.link as string} css={navLinkStyles}>
                  {data.text}
                </Link>
              </NavigationMenu.Link>
            </NavigationMenu.Item>
          )
        )}
        {children}
      </MenuList>
    </NavigationMenu.Root>
  );
};

export const NavItem = ({
  children,
  ...props
}: {
  children: ReactNode;
  className?: string;
  id?: string;
}) => (
  <NavigationMenu.Item {...props}>
    <NavigationMenu.Link asChild>{children}</NavigationMenu.Link>
  </NavigationMenu.Item>
);
