exports.components = {
  "component---2393973405-e-pediatric-dentist-by-age-one-or-upon-first-tooth-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/pearl-pediatric-dentistry/pearl-pediatric-dentistry/src/content/blogs/why-your-child-should-visit-the-pediatric-dentist-by-age-one-or-upon-first-tooth/index.mdx" /* webpackChunkName: "component---2393973405-e-pediatric-dentist-by-age-one-or-upon-first-tooth-index-mdx" */),
  "component---src-components-pediatric-services-index-tsx": () => import("./../../../src/components/pediatric-services/index.tsx" /* webpackChunkName: "component---src-components-pediatric-services-index-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-doctors-dr-blerta-tsx": () => import("./../../../src/pages/doctors/dr-blerta.tsx" /* webpackChunkName: "component---src-pages-doctors-dr-blerta-tsx" */),
  "component---src-pages-doctors-dr-sam-tsx": () => import("./../../../src/pages/doctors/dr-sam.tsx" /* webpackChunkName: "component---src-pages-doctors-dr-sam-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-office-tour-tsx": () => import("./../../../src/pages/office-tour.tsx" /* webpackChunkName: "component---src-pages-office-tour-tsx" */),
  "component---src-pages-thankyou-tsx": () => import("./../../../src/pages/thankyou.tsx" /* webpackChunkName: "component---src-pages-thankyou-tsx" */),
  "component---src-pages-upcoming-events-tsx": () => import("./../../../src/pages/upcoming-events.tsx" /* webpackChunkName: "component---src-pages-upcoming-events-tsx" */),
  "component---src-templates-blog-page-tsx": () => import("./../../../src/templates/blog-page.tsx" /* webpackChunkName: "component---src-templates-blog-page-tsx" */),
  "component---src-templates-blog-post-tsx-content-file-path-home-runner-work-pearl-pediatric-dentistry-pearl-pediatric-dentistry-src-content-blogs-braces-beauty-a-orthodontists-guide-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/pearl-pediatric-dentistry/pearl-pediatric-dentistry/src/content/blogs/braces-beauty-a-orthodontists-guide/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-home-runner-work-pearl-pediatric-dentistry-pearl-pediatric-dentistry-src-content-blogs-braces-beauty-a-orthodontists-guide-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-home-runner-work-pearl-pediatric-dentistry-pearl-pediatric-dentistry-src-content-blogs-navigating-the-costs-of-orthodontic-treatment-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/pearl-pediatric-dentistry/pearl-pediatric-dentistry/src/content/blogs/navigating-the-costs-of-orthodontic-treatment/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-home-runner-work-pearl-pediatric-dentistry-pearl-pediatric-dentistry-src-content-blogs-navigating-the-costs-of-orthodontic-treatment-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-home-runner-work-pearl-pediatric-dentistry-pearl-pediatric-dentistry-src-content-blogs-straightening-smiles-with-braces-a-guide-for-parents-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/pearl-pediatric-dentistry/pearl-pediatric-dentistry/src/content/blogs/straightening-smiles-with-braces-a-guide-for-parents/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-home-runner-work-pearl-pediatric-dentistry-pearl-pediatric-dentistry-src-content-blogs-straightening-smiles-with-braces-a-guide-for-parents-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-home-runner-work-pearl-pediatric-dentistry-pearl-pediatric-dentistry-src-content-blogs-when-do-kids-start-losing-baby-teeth-and-getting-permanent-teeth-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/home/runner/work/pearl-pediatric-dentistry/pearl-pediatric-dentistry/src/content/blogs/when-do-kids-start-losing-baby-teeth-and-getting-permanent-teeth/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-home-runner-work-pearl-pediatric-dentistry-pearl-pediatric-dentistry-src-content-blogs-when-do-kids-start-losing-baby-teeth-and-getting-permanent-teeth-index-mdx" */)
}

