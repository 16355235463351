export type NavLinksTypes = {
  text: string;
  link?: string;
  links?: NavLinksTypes;
}[];

export const navigators: NavLinksTypes = [
  {
    text: "about us",
    links: [
      { text: "about the practice", link: "/about" },
      // { text: "meet the doctors", link: "/about" },
      { text: "office tour", link: "/office-tour/" },
    ],
  },
  {
    text: "pediatric dentistry",
    links: [
      {
        text: "pediatric dentistry",
        link: "/pediatric-dentistry/pediatric",
      },
      {
        text: "first dental visit",
        link: "/pediatric-dentistry/first-dental-visit",
      },
      {
        text: "dental cleaning",
        link: "/pediatric-dentistry/dental-cleaning",
      },
      {
        text: "preventive dental treatments",
        link: "/pediatric-dentistry/preventive-dental-treatments",
      },
      {
        text: "restorative dentistry",
        link: "/pediatric-dentistry/restorative-dentistry-specialist",
      },
      {
        text: "dental fillings",
        link: "/pediatric-dentistry/pediatric-dental-fillings",
      },
      {
        text: "special dental needs",
        link: "/pediatric-dentistry/special-dental-needs",
      },
      { text: "sedation", link: "/pediatric-dentistry/sedation" },
      {
        text: "general anesthesia",
        link: "/pediatric-dentistry/general-anesthesia",
      },
      {
        text: "dental emergency treatment",
        link: "/pediatric-dentistry/dental-emergency-treatment",
      },
    ],
  },
  {
    text: "orthodontics care",
    links: [
      { text: "metal braces", link: "/orthodontics-care/metal-braces" },
      {
        text: "clear braces",
        link: "/orthodontics-care/clear-braces",
      },
      {
        text: "clear aligners",
        link: "/orthodontics-care/clear-aligners",
      },
      // {
      //   head: "Adolescent Orthodontic Treatment",
      //   link: "/orthodontics-care/adolescent-treatment",
      // },
      {
        text: "interceptive orthodontics",
        link: "/orthodontics-care/interceptive-orthodontic",
      },
      // {
      //   text: "adolescent orthodontic treatment",
      //   link: "/orthodontics-care/adolescent-treatment",
      // },
      {
        text: "orthodontics for adults",
        link: "/orthodontics-care/orthodontic-treatment-for-adults",
      },
    ],
  },
  {
    text: "let’s be social",
    links: [
      {
        text: "facebook",
        link: "https://www.facebook.com/profile.php?id=100086023868364",
      },
      {
        text: "instagram",
        link: "https://www.instagram.com/pearl_pdo/?hl=en",
      },
      { text: "upcoming events", link: "/upcoming-events" },
    ],
  },
  { text: "blog", link: "/blog/" },
];
