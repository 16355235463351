import { css, Global, Theme } from "@emotion/react";
import emotionNormalize from "emotion-normalize";
import "@fontsource/poppins";
import "@fontsource/poppins/700.css";

export const theme: Theme = {
  colors: {
    darkYellow: "#D9A919",
    lightYellow: "#FFCF03",
    offWhite: "#F3F0E9",
    black: "#31280C",
    white: "#ffffff",
    tomatoRed: "#B56959",
    lightGreen: "#87D8A6",
    lightGray: "#d3d3d3",
    darkGolden: "#AC9470",
  },
  fontSizes: {
    h2: "48px",
    h3: "36px",
    h4: "24px",
    p: "16px",
  },
};

export const GlobalStyles = () => {
  return (
    <Global
      styles={() => css`
        body {
          font-family: "Poppins", sans-serif;
          top: 0 !important;
        }
        .no-scrollbar::-webkit-scrollbar {
          display: none;
        }
        .carouselItemClass {
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .carouselContainerClass {
          padding-bottom: 75px;
        }
        a {
          text-decoration: none;
          color: black;
        }
        ${emotionNormalize};

        .skiptranslate {
          display: none;
        }
      `}
    />
  );
};
