import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogOpen,
} from "./components/DialogPrimitive";
import { ReactComponent as ChevronIcon } from "../../../../assets/icons/nav-chevron.svg";
import { ReactComponent as CloseIcon } from "../../../../assets/icons/close.svg";
import { ReactComponent as MenuIcon } from "../../../../assets/icons/menu.svg";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import useRouteChange from "@s/useRouteChange";
import useWindowSize from "@s/useWindowSize";
import { navigators } from "constants/header";
import { Fragment, useState } from "react";
import { navBtnStyles, navLinkStyles, subNavLinkStyles } from "./NavLinkStyles";
import { theme } from "@/GlobalStyles";
import { Link } from "gatsby";
import {
  Accordion,
  AccordionBtn,
  AccordionContent,
  AccordionItem,
} from "./components/AccordionPrimitive";
import LanguagePicker from "./components/language-picker";
import ArrowButton from "@/common/arrow-button";
import { HomeLogo } from ".";

const TriggerBtn = styled.button`
  border: none;
  background: transparent;
  outline: none;
  padding: 0;
  cursor: pointer;

  @media (min-width: 1280px) {
    display: none;
  }
`;

const overlayStyles = css`
  display: none;
`;

const contentStyles = css`
  background-color: ${theme.colors.tomatoRed};
  padding: 16px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 102;

  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
  }
`;

const NavMenu = styled.nav`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
  height: calc(100% - 140px);
  overflow-y: auto;
  max-width: 230px;
  margin: 0 auto;

  @media (min-height: 720px) {
    height: calc(100% - 150px);
  }
`;

export const MobNav = () => {
  const [open, setOpen] = useState(false);

  const { width } = useWindowSize();

  useRouteChange(setOpen);

  return width < 1280 ? (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogOpen>
        <TriggerBtn aria-label="open navigation menu">
          <MenuIcon />
        </TriggerBtn>
      </DialogOpen>

      <DialogContent
        contentStyles={contentStyles}
        overlayStyles={overlayStyles}
        title="website main navigation menu"
      >
        <div>
          <DialogClose>
            <TriggerBtn aria-label="close navigation menu">
              <CloseIcon />
            </TriggerBtn>
          </DialogClose>
          <HomeLogo />
        </div>
        <Accordion asChild type="single" collapsible>
          <NavMenu>
            {navigators.map((data, i) =>
              data.links ? (
                <AccordionItem value={data.text}>
                  <AccordionBtn css={navBtnStyles}>
                    {data.text} <ChevronIcon />
                  </AccordionBtn>
                  <AccordionContent>
                    {data.links.map((subData, i) => (
                      <Link
                        key={i}
                        to={subData.link as string}
                        css={css`
                          ${subNavLinkStyles};
                          :last-of-type {
                            margin-bottom: 44px;
                          }
                        `}
                      >
                        {subData.text}
                      </Link>
                    ))}
                  </AccordionContent>
                </AccordionItem>
              ) : (
                <Link to={data.link as string} css={navLinkStyles} key={i}>
                  {data.text}
                </Link>
              )
            )}
            <LanguagePicker
              background={theme.colors.white}
              color="#CBB898"
              margin="32px 0"
            />
            <Link to="/contact">
              <ArrowButton
                title="CONTACT US"
                titleColor={theme.colors.white}
                border={`2px solid ${theme.colors.white}`}
                direction="row-reverse"
                mobileDirection="row-reverse"
              />
            </Link>
          </NavMenu>
        </Accordion>
      </DialogContent>
    </Dialog>
  ) : (
    <Fragment />
  );
};
