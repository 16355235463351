import { theme } from "@/GlobalStyles";
import styled from "@emotion/styled";
import { mq } from "styles/media-query";

export const MainContainer = styled.div`
  height: max-content;
  background-color: ${theme.colors.tomatoRed};
  padding: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  ${mq["md"]} {
    padding: 64px 10px 40px 10px;
  }
`;

export const ContentContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 100px;
  margin-top: 64px;
  padding: 0px 20px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;

  ${mq["sm"]} {
    justify-content: flex-start;
  }
`;

export const NavigationContainer = styled.div<{
  marginRight?: string;
}>`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  ${(props) => props.marginRight && `margin-right: ${props.marginRight}`};
`;

export const NavigationOptions = styled.div`
  margin-top: 28px;
`;

export const ContactContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
`;

export const DivWithIcon = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const SocialContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
`;

export const Socials = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 35px;
  width: 100%;
`;

export const PoweredBy = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 72px;
  ${mq["sm"]} {
    flex-direction: column;
  }
`;

export const PoweredText = styled.p`
  margin: 0;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.25em;
  color: ${theme.colors.white};
  margin-left: 32px;
  ${mq["sm"]} {
    margin-top: 20px;
    margin-left: 0px;
  }
`;
