import { Seo } from "@/common/Seo";
import Footer from "./components/footer";
import Header from "./components/header";
import { Layout } from "./layout";

export const DefaultLayout: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <Layout>
      <Seo />
      <Header />
      {children}
      <Footer />
    </Layout>
  );
};
