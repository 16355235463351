import React, { useState } from "react";
import { theme } from "@/GlobalStyles";
import { Paragraph } from "styles/typography";
import { ArrowButtonContainer, RoundArrowButton } from "./arrow-button";
import Arrow from "svgs/arrow";

interface Props {
  title?: string;
  titleColor?: string;
  border?: string;
  direction?: string;
  rotate?: string;
  arrowMargin?: string;
  mobileDirection?: string;
  gradient?: boolean;
}

const ArrowButton = ({
  title,
  titleColor,
  border,
  direction,
  rotate,
  arrowMargin,
  mobileDirection,
  gradient,
}: Props) => {
  const [hover, setHover] = useState(false);
  return (
    <ArrowButtonContainer
      direction={direction}
      mobileDirection={mobileDirection}
    >
      <RoundArrowButton
        border={border}
        rotate={rotate}
        arrowMargin={arrowMargin}
        hover={hover}
      >
        <Arrow color={titleColor} />
      </RoundArrowButton>
      <Paragraph
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        fontWeight="700"
        textTransform="capitalize"
        marginRight="17px"
        cursor="pointer"
        gradient={gradient}
        color={titleColor || theme.colors.tomatoRed}
      >
        {title}
      </Paragraph>
    </ArrowButtonContainer>
  );
};

export default ArrowButton;
