import styled from "@emotion/styled";
import { mq } from "styles/media-query";
import { keyframes, css } from "@emotion/react";

const forwardAnimation = keyframes`
 from {transform: rotate(0deg); zoom: 1 }
 to { transform: rotate(45deg); zoom: 1.15  }
`;

const reverseAnimation = keyframes`
 from {transform: rotate(45deg) }
 to { transform: rotate(0deg)  }
`;

const zoomIn = keyframes`
 from {zoom: 1 }
 to { zoom: 1.15  }
`;

// const zoomOut = keyframes`
//  from {zoom: 1.1 }
//  to { zoom: 1  }
// `;

export const RoundArrowButton = styled.div<{
  border?: string;
  rotate?: string;
  arrowMargin?: string;
  hover?: boolean;
}>`
  border: ${(props) => props.border || "2px solid #b56959"};
  transform: ${(props) => props.rotate || "rotate(0deg)"};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 45px;
  border-radius: 50px;
  flex-shrink: 0;
  margin: ${(props) => props.arrowMargin || "0px 16px 0px 8px"};
  animation-duration: 0.1s;
  animation-fill-mode: forwards;
  animation-name: ${(props) =>
    props.hover && !props.rotate
      ? forwardAnimation
      : !props.hover && !props.rotate
      ? reverseAnimation
      : props.hover
      ? zoomIn
      : ""};
  ${(props) => css`
    &:hover {
      animation-name: ${!props.rotate ? forwardAnimation : zoomIn};
    }
  `}
`;

export const ArrowButtonContainer = styled.div<{
  direction?: string;
  mobileDirection?: string;
}>`
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-direction: ${(props) => props.direction || "row"};

  ${mq["sm"]} {
    flex-direction: ${(props) => props.mobileDirection || "row"};
  }
`;
