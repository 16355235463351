/* eslint-disable @typescript-eslint/no-explicit-any */
import { cloneElement } from "react";
import { LayoutProvider } from "layouts/LayoutProvider";
import { DefaultLayout } from "layouts/DefaultLayout";
import type { GatsbyBrowser } from "gatsby";
import AOS from "aos";
import "aos/dist/aos.css";

export const wrapRootElement: GatsbyBrowser["wrapRootElement"] = ({
  element,
}) => {
  AOS.init({ once: true });
  return <LayoutProvider>{element}</LayoutProvider>;
};

export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({
  element,
  props,
}) => {
  if ((element.type as any).Layout === false) {
    return cloneElement(element, props);
  }
  const Layout = (element.type as any).Layout ?? DefaultLayout;
  return <Layout {...props}>{element}</Layout>;
};

export const onRouteUpdate: GatsbyBrowser["onRouteUpdate"] = function () {
  // Don't track while developing.
  if (typeof window !== "undefined") {
    if (typeof window.fbq === `function`) {
      window.fbq("track", "ViewContent");
    }
  }
};
